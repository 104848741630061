@import '../../global/styles/lib';
$submit-height: 2.25rem;
$icon-scale: 55%;
$search-form-z: 20;
$search-form-overflow-z: 25;

$search-icon: '/wp-content/themes/custom/global/images/icons/ss-custom/search-white.svg';

.search-display {
    .search-display__icon {
        left: 0;
        top: rem-calc(-1);
        width: $submit-height;
        height: $submit-height;
        position: relative;
        background-image: url($search-icon) !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        transform: scaleX(-1);
        background-color: $most-red;
        background-size: $icon-scale !important;
        border-radius: 50%;
        cursor: pointer;
        border: 0;
        margin-left: 1.5rem;
        opacity: 1;
        transition: all $header-transition;

        #{$hovfoc} {
            border-radius: 0%;
        } // &:hover, &:focus

    } // .search-display__icon    

} // .search-display

.search-display__form {
    display: none;
    @include absolute-full;
    z-index: $search-form-z;
    padding: 0.5rem;
    transform: translateY(-100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: all $hover-transition;

    &::before {
        content: "";
        @include absolute-full;
        background-color: $white;
        transform: translateY(-100%);
        opacity: 0;
        transition: all $hover-transition;
    } // &::after

    &.slide-in {
        transform: translateY(-0.5rem);
        &::before {
            transform: translateY(0);
            opacity: 0.95;
        } // &::before
    } // &.slide

    &.slide-out {
        transform: translateY(100%);
        clip-path: polygon(-100% -100%, 0 -100%, 0 0, -100% 0);
        &::before {
            transform: translateY(100%);
        } // &::before
    } // &.slide-out

    .content {
        position: relative;
        transform: translateY(0.25rem);
        z-index: $search-form-z;
        display: flex;
        justify-content: center;
        form {
            width: 80%;
            transition: width $hover-transition;
            align-items: flex-end;
            @include breakpoint(medium up) {
                width: 60%;
            }
            .search-submit {
                font-size: 0.75rem;
                padding: 0.5rem;
                height: 2rem;
            } // .search-submit

        } // form
        .close {
            width: 10%;
            margin-left: 1rem;
            display: flex;
            justify-content: center;
            align-self: center;
            font-weight: 700;
            font-size: 1.25rem;
            border: none;

            & > span {
                background-color: $most-red;
                color: $white;
                border-radius: 0%;
                width: 2rem;
                height: 2rem;
                line-height: 2rem;
                text-align: center;
                cursor: pointer;
                transition: all $hover-transition;

            } // & > span

            &:hover,
            &:focus {
                background: $transparent !important;
                span {
                    outline: none;
                    border-radius: 50%;
                } // span
            } // &:hover, &:focus

        } // .close
    } // .content

} // .search-display__form

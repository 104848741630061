/**
 * Imports libraries of settings, mixins, functions, etc. that should be available
 * to all stylesheets. MUST NOT OUTPUT STYLES.
 *
 * @author    <corey@chatmandesign.com>
 * @since     3.0.0
 */
/**
 * Breakpoint range variables for the lock mixin.
 * Convert the breakpoints map into range variables like we had in ZF5. The
 * magic number at the end is the difference between the breakpoint we define in
 * $breakpoints and the actual max-width value spit out by the breakpoints()
 * mixin when we give it an "only" argument (to avoid overlap. See the ZF5 media
 *  query range variables for reference.)
 *
 * $small-range is a special snowflake. The whole purpose of the lock mixin is
 * to let you set a property to a fixed value up to a certain breakpoint, then
 * have that value increase in a linear fashion until you hit another
 * breakpoint, and then keep the value fixed again for the rest of the way. The
 * way the breakpoints are defined, the small range would be 0px - 640px. But
 * we're not planning on supporting devices smaller than 320px, so it's far more
 * useful for the small range to be 320px to 640px. That way, the $min-value you
 * pass to the lock mixin is anchored to the smallest device width we support,
 * and the $max-value is anchored to the breakpoint between small and medium
 * ranges.
 *
 * @author   <dustin@chatmandesign.com>
 */
/**
 * Sass Mixins
 *
 * @package   seedyjoints
 * @since     1.0
 */
/**
 * Extend element's background to left and right edges of viewport.
 *
 * @param string $position  Any valid CSS position value, or NULL/FALSE to not set
 *                          position; but note the element must create a positioning
 *                          context for the bleed effect to work. Default: relative.
 * @param string $direction Direction the bleed will extend in -- left, right, both.
 *                          Deafult: both.
 */
/**
 * Lock (Fluid Type Mixin -- CD Remix)
 * Created 10/11/16 @ 02:19:30 PM
 *
 * Original from here:
 * http://madebymike.com.au/writing/precise-control-responsive-typography/
 *
 * This is a modified version of the above mixin that takes a single SCSS list
 * instead of two variables for min and max VW. This makes it easier to use with
 * the existing Foundation range variables in _settings.scss. You can of course
 * still pass a plain list e.g. "(20rem, 40rem)" as the argument for the range
 * parameter if you need a custom range.
 *
 * @param      $properties A space separated list of CSS properties
 * @param      $vw-range A SCSS list defining a viewport width range like
 *             "(20em, 40em)".
 * @param      $min-value A minimum property value in px/em/rem
 * @param      $max-value A maximum property value in px/em/rem
 * @author     Chatman Design / Dustin Paluch <dustin@chatmandesign.com>
 */
/**
 * List items inline, separated by pipes by default.
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 */
/**
 * Transition property with vendor prefixes (DEPRECATED)
 *
 * DEPRECATED now that we use autoprefixer. Just use the standard CSS property.
 *
 * I totally rewrote this to be cleaner and easier to use. You'll need to be using
 * Sass 3.2+ for these to work. Thanks to @anthonyshort for the inspiration on these.
 *
 * USAGE: @include transition(<property> <time> <easing>);
 */
/**
 * CSS3 Gradients with vendor prefixes
 *
 * Be careful with these since they can really slow down your CSS. Don't overdo it.
 */
/* @include css-gradient(#dfdfdf,#f8f8f8); */
/**
 * Foundation for Sites by ZURB
 * Version 6.4.0
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.search-display .search-display__icon {
  left: 0;
  top: -0.0625rem;
  width: 2.25rem;
  height: 2.25rem;
  position: relative;
  background-image: url("/wp-content/themes/custom/global/images/icons/ss-custom/search-white.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  transform: scaleX(-1);
  background-color: #c10230;
  background-size: 55% !important;
  border-radius: 50%;
  cursor: pointer;
  border: 0;
  margin-left: 1.5rem;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
.search-display .search-display__icon:hover, .search-display .search-display__icon:focus {
  border-radius: 0%;
}

.search-display__form {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  padding: 0.5rem;
  transform: translateY(-100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  transition: all 0.3s ease-in-out;
}
.search-display__form::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fefefe;
  transform: translateY(-100%);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.search-display__form.slide-in {
  transform: translateY(-0.5rem);
}
.search-display__form.slide-in::before {
  transform: translateY(0);
  opacity: 0.95;
}
.search-display__form.slide-out {
  transform: translateY(100%);
  clip-path: polygon(-100% -100%, 0 -100%, 0 0, -100% 0);
}
.search-display__form.slide-out::before {
  transform: translateY(100%);
}
.search-display__form .content {
  position: relative;
  transform: translateY(0.25rem);
  z-index: 20;
  display: flex;
  justify-content: center;
}
.search-display__form .content form {
  width: 80%;
  transition: width 0.3s ease-in-out;
  align-items: flex-end;
}
@media screen and (min-width: 40em) {
  .search-display__form .content form {
    width: 60%;
  }
}
.search-display__form .content form .search-submit {
  font-size: 0.75rem;
  padding: 0.5rem;
  height: 2rem;
}
.search-display__form .content .close {
  width: 10%;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-self: center;
  font-weight: 700;
  font-size: 1.25rem;
  border: none;
}
.search-display__form .content .close > span {
  background-color: #c10230;
  color: #fefefe;
  border-radius: 0%;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.search-display__form .content .close:hover, .search-display__form .content .close:focus {
  background: rgba(0, 0, 0, 0) !important;
}
.search-display__form .content .close:hover span, .search-display__form .content .close:focus span {
  outline: none;
  border-radius: 50%;
}